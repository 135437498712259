<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="code" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('code')">
                                    <b-form-input
                                        v-model="defineForm.code"
                                        :state="errors[0] ? false:null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('name')">
                                    <b-form-input
                                        v-model="defineForm.name"
                                        :state="errors[0] ? false:null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-8 col-md-8 col-lg-8">
                            <ValidationProvider name="registration_types" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('registration_types')">
                                    <parameter-selectbox
                                    v-model="defineForm.registration_types"
                                    code="registration_types"
                                    :multiple="true"></parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="program_codes" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('program_codes')">
                                    <b-form-input
                                        v-model="defineForm.program_codes"
                                        :state="errors[0] ? false:null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="ebys_type_id" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('ebys_type_id')">
                                    <b-form-input
                                        v-model="defineForm.ebys_type_id"
                                        :state="errors[0] ? false:null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="is_required" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('is_required')">
                                    <b-form-radio-group
                                        :validate-error="errors[0]"
                                        v-model="defineForm.is_required">
                                        <b-form-radio value="true">{{$t('yes')}}</b-form-radio>
                                        <b-form-radio value="false">{{$t('no')}}</b-form-radio>
                                    </b-form-radio-group>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import RegistrationService from "@/services/RegistrationService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ParameterSelectbox,
    },

    data() {
        return {
            defineForm: {
                code: null,
                name: null,
                registration_types: [],
                program_codes: null,
                is_required: null,
                ebys_type_id: null
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("registration_document_types")
        }
    },
    methods: {

        async sendForm() {

            const isValid = await this.$refs.updateForm;
            if (isValid) {
                this.defineForm.is_required = this.defineForm.is_required === 'true' ? 1 : 0
                let formData = {
                    ...this.defineForm
                }
                if(formData.program_codes){
                    formData.program_codes = formData.program_codes.replaceAll(' ','').split(',')
                }

                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('registration_documents_confirm_text'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {
                    if(response.isConfirmed == true) {
                        RegistrationService.storeDocumentType(formData).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.$emit('hide')
                            this.defineForm = {
                                code: null,
                                name: null,
                                registration_types: [],
                                program_codes: null,
                                is_required: null,
                                ebys_type_id: null
                            }
                            this.$refs.updateForm.reset();
                        }).catch((e) => {
                            this.showErrors(e, this.$refs.updateForm);
                        })
                    }
                })
            }
        }
    },

    created() {

    },
};
</script>
